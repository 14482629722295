import "./inspiring-story.scss";
import React, { useEffect, useState } from "react";
import axios from "helpers/AxiosConfig";
// Bootstrap
import { Container, Form, Button } from "react-bootstrap";
// React videoJS Player
import VideoPlayer from "react-video-js-player";
// Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// data
import localData from "data/data.json";
let pageData = localData.upload_inspiring_stories;
export default function UploadInspiringStories(props) {
  // story guideLine
  const [guideLine, setGuideLine] = useState([]);
  const [isGuidlineLoaded, setisGuidlineLoaded] = React.useState(false);
  // upload story
  const [filePath, setFilePath] = useState();
  const [description, setDescription] = useState();
  const [uploadFile, setUploadFile] = useState();
  const [acceptAllTerms, setAcceptAllTerms] = useState();
  // Bootstrap validation
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    axios
      .get("/success_story_guidelines")
      .then(function (response) {
        setGuideLine(response.data.data);
        setisGuidlineLoaded(true);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  }, []);

  let SubmitYourStory = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(true);
      if (acceptAllTerms) {
        axios
          .post("/success_story", {
            comment: description,
            file: filePath,
          })
          .then((response) => {
            setFilePath("");
            setDescription("");

            // Send message to user
            let message = "Your Story send for Admin Approval";
            toast.success(message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          })
          .catch(function (error) {
            console.log(error); // handle error
          });
      }
    }
  };

  return (
    <div className="ts-page-content ts-upload-story">
      <div className="ts-upload-story__main">
        <Container>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Form noValidate validated={validated} onSubmit={SubmitYourStory}>
            <div className="mb-10">
              <div className="d-flex justify-content-center mb-03 ">
                <Form.Group controlId="formFile" className="form-file">
                  <Button
                    variant="primary-1"
                    className="d-flex align-items-center gap-2"
                  >
                    <img
                      width="15"
                      src="/assets/icons/plus-white.svg"
                      alt="..."
                    />
                    <Form.Control
                      type="file"
                      onChange={(e) => setFilePath(e.target.value)}
                      required
                    />
                    {props.rtlSwitch
                      ? pageData.upload_button_ar
                      : pageData.upload_button_en}
                  </Button>
                </Form.Group>
              </div>
              <p className="text-center">{filePath}</p>
            </div>

            {isGuidlineLoaded ? (
              props.rtlSwitch ? (
                <div className="video-wrapper mb-06">
                  <VideoPlayer
                    controls={true}
                    src={guideLine.success_story_video_ar}
                    // poster={this.state.video.poster}
                  />
                </div>
              ) : (
                <div className="video-wrapper mb-06">
                  <VideoPlayer
                    controls={true}
                    src={guideLine.success_story_video_en}
                    // poster={this.state.video.poster}
                  />
                </div>
              )
            ) : (
              ""
            )}

            <p className="ts-desc-lg mb-10">
              {props.rtlSwitch
                ? guideLine.success_story_description_ar
                : guideLine.success_story_description_en}
            </p>

            <Form.Group className="mb-08">
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="Description"
                onChange={(e) => setDescription(e.target.value)}
                required
                value={description}
              />
            </Form.Group>
            <Form.Group className="mb-10">
              <Form.Label className="ms-sm-3 mb-08 heading-4">
                {props.rtlSwitch
                  ? pageData.upload_select_title_ar
                  : pageData.upload_select_title_en}
              </Form.Label>
              <Form.Select
                size="lg"
                className="mb-10"
                onChange={(e) => setUploadFile(e.target.value)}
              >
                <option>Upload from Computer</option>
                <option>Upload from Mobile</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-10 ms-sm-3">
              <Form.Check
                type="checkbox"
                label="I accept all terms and conditions."
                onChange={(e) => setAcceptAllTerms(e.target.checked)}
                required
              />
            </Form.Group>
            <div className="text-center">
              <Button
                variant="primary-07"
                className="col-sm-6 col-lg-3"
                type="submit"
              >
                {props.rtlSwitch
                  ? pageData.submit_button_ar
                  : pageData.submit_button_en}
              </Button>
            </div>
          </Form>
        </Container>
      </div>
    </div>
  );
}
