import "./course-detail.scss";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";

import { useLocation } from "react-router-dom";
// Components
import VideoPlayer from "components/VideoPlayer/VideoPlayer";
import PaymentMethods from "components/Payment/PaymentMethods";
import { PaymentVarified } from "components/Payment/PaymentVarified";
import { ButtonGreen } from "components/Buttons/Buttons";
import QualityCirtificate from "components/QualityCirtificate/QualityCirtificate";
import ChapterFromDetails from "components/Chapter/ChaptersFromDetails";

import VimeoPlayer from "components/VideoPlayer/Vimeo";

import axios from "helpers/AxiosConfig";

export default function CourseDetail(props) {
  const [course, setCourse] = useState([]);
  const [chapters, setChapters] = useState([]);

  // geting course number form url
  const location = useLocation();
  const courseNumber = location.pathname.split("/").pop();

  useEffect(() => {
    getCourseDetails();
  }, [location.pathname, courseNumber]);

  const getCourseDetails = () => {
    axios
      .get(`course_detail/${courseNumber}`)
      .then(function (response) {
        setCourse(response.data.course);
        setChapters(response.data.course.chapters);
        // console.log(response.data.course.vimeo_link.split("/").pop());
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  };

  function courseMainFile(filetype) {
    switch (filetype) {
      case "image":
        return <img className="w-100" src={course.file_url} alt="..." />;
      case "video":
        return (
          <VideoPlayer src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4" />
        );
      case "vimeo":
        return <VimeoPlayer Url={course.vimeo_link} className="mb-10" />;
      default:
        return "";
    }
  }

  return (
    <div>
      <div className="ts-hero">
        <Container className="w-100">
          {courseMainFile(course.file_type)}
        </Container>
      </div>

      <div className="ts-payment-method text-gray-02">
        <div className="ts-payment-method__content">
          <Container>
            <p className="heading-5  mb-10">{course.name_en}</p>
            {/*<div className="text-center mb-08">
              <ButtonGreen
                addToCartMultipleCourses={props.addToCartMultipleCourses}
                allCoursesInProgrammId={[courseNumber]}
                text="Buy Now"
              />
            </div>*/}
            <div className="mb-06">
              <PaymentMethods />
            </div>
            <PaymentVarified rtlSwitch={props.rtlSwitch} />
          </Container>
        </div>
      </div>

      <ChapterFromDetails chaptersList={chapters} rtlSwitch={props.rtlSwitch} />
      <QualityCirtificate />

      <Container className="text-center mt-14">
        <h3 className="text-primary-01 mb-06 ">
          Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing ?
        </h3>
        <p className="ts-desc-lg mb-14">
          Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam
          Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna Aliquyam Erat
        </p>
        <h3 className="text-primary-01 mb-06 ">
          Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing ?
        </h3>
        <p className="ts-desc-lg mb-13">
          Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam
          Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna Aliquyam Erat
        </p>
      </Container>
      {/* <div className="ts-payment-method ts-payment-method--bg-gray text-gray-02 text-center">
        <div className="ts-payment-method__content">
          <Container>
            <p className="heading-5  mb-10">
              Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam
              Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna Aliquyam
              Erat
            </p>
            <div className="text-center mb-08">
              <ButtonPrimary text="Buy Now" />
            </div>
            <div className="mb-06">
              <PaymentMethods />
            </div>
            <PaymentVarified2 />
          </Container>
        </div>
      </div>
      <div className="ts-payment-method text-gray-02 text-center">
        <div className="ts-payment-method__content">
          <Container>
            <p className="heading-5  mb-10">
              Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam
              Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna Aliquyam
              Erat
            </p>
            <div className="text-center mb-08">
              <ButtonPrimary text="Buy Now" />
            </div>
            <div className="mb-06">
              <PaymentMethods />
            </div>
            <PaymentVarified2 />
          </Container>
        </div>
      </div> */}
    </div>
  );
}
