import React, {useEffect} from "react";
import Form from "react-bootstrap/Form";
import Select from 'react-select'


export default function TsFormControl(props) {

  const [options, setOptions] = React.useState([]);

  useEffect(() => {

    setOptions(props.dataArray.map((country) => ({
      value: country.id,
      label: `(+${country.phone}) ${country.name}`,
      phone: country.phone, // You can store other data if needed
    })));

  }, [props.dataArray]);

  // Custom styles
  const customStyles = {
    control: (base) => ({
      ...base,
      fontSize: 'clamp(1.3rem, 4vw, 1.5rem)',
      border: '2px solid white', // Bootstrap border color
      borderRadius: 'var(--bs-border-radius-pill) !important', // Bootstrap border-radius
      padding: '0.88rem 1.25rem 0.98rem 4.5rem', // Padding similar to Bootstrap inputs
      backgroundColor: '#f3f3f3',
    }),
    input: (base) => ({
      ...base,
      color: '#616161', // Bootstrap text color
    }),
    placeholder: (base) => ({...base, color: '#616161'}),
    menu: (base) => ({
      ...base,
      zIndex: 999, // To ensure the dropdown is above other elements
    }),
  };

  const changeHandler = value => {

    console.log('value', value);
    props.changeValue(value.value)
  }

  return (
    <Form.Group
      className={
        "ts-form-group " + (props.mb === undefined ? "mb-04" : props.mb)
      }
      controlId={props.id}
    >
      {/* <div className="position-relative"> */}

      {/*<Form.Select
        className="rounded-pill shadow-sm"
        type={props.Type}
        placeholder={props.Placeholder}
        onChange={(e) => {
          props.changeValue(e.target.value); // return input value to parent
        }}
        required
      >
        <option value="" disabled selected>
          {props.defaultDisabledOption}
        </option>
        {props.dataArray.map((coutry) => {
          return (
            <option
              key={coutry.id}
              value={coutry.id}
              data-coutry-code={coutry.phone}
            >
              {`(+${coutry.phone} ) ${coutry.name}`}
            </option>
          );
        })}
      </Form.Select>*/}

      <Select styles={customStyles} required
              placeholder={props.Placeholder}
              options={options} onChange={changeHandler} />

      <div className="ts-form-group__icon-container">
        <img
          className="ts-form-group__icon"
          src={props.Icon}
          alt={props.IconAlt}
        />
      </div>
    </Form.Group>
  );
}
