import "./Footer.scss";
import React, { useEffect, useState } from "react";
import axios from "helpers/AxiosConfig";

import Stack from "react-bootstrap/Stack";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa6";
import { CgMail } from "react-icons/cg";

// Images
import logo from "../../assets/icons/white-Arabic.svg";
import facebook from "../../assets/icons/facebook.svg";
import tiktok from "../../assets/icons/tiktok.svg";
import twitter from "../../assets/icons/Twitter.svg";
import youtube from "../../assets/icons/youtube.svg";
import telegram from "../../assets/icons/telegram.svg";
import pinterest from "../../assets/icons/Pinterest.svg";
import instagram from "../../assets/icons/Instagram.svg";
import google from "../../assets/icons/Google.svg";
import linkedin from "../../assets/icons/LinkedIn.svg";
import ExternalLinks from "../../assets/icons/Icon-feather-external-link.svg";

// data
import localData from "data/data.json";
let pageData = localData.footer;

export default function Footer(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("footer")
      .then(function (response) {
        setData(response.data.data);
      })
      .catch(function (error) {
        console.log(error); // handle error
      });
  }, []);
  console.log(props.onProgramDetailsPage)
  return (
    <footer
      className={`footer ${props.rtlSwitch ? "rtl-footer" : ""}`}
      data-testid="Footer"
    >
      {props.onProgramDetailsPage == true ?
        <div className="onProgramDetails-con">
          <h2 className="mb-3">{props.rtlSwitch ? pageData.contact_ar : pageData.contact_en}</h2>
          <div className="flex-aswad-center mb-3">
            <a className="icon" href="https://wa.me/96550406406" target="_blank"><FaWhatsapp size={45} /></a>
            <a className="icon" href="mailto:hello@reham.com" target="_blank"><CgMail size={50} /></a>

          </div>

        </div>
        :
        <Container>
          <div className="footer__main">
            <div className="footer__logo">
              <img src={logo} alt="reham logo" />
            </div>
            <ul className="footer__list">
              <li className="footer-links__title">
                <img src={ExternalLinks} alt="external links" />{" "}
                {props.rtlSwitch
                  ? pageData.useful_links_ar
                  : pageData.useful_links_en}
              </li>

              {props.isLogged ? (
                <li>
                  <Link className="footer__link" to="/my-programs">
                    {props.rtlSwitch
                      ? pageData.programs_ar
                      : pageData.programs_en}
                  </Link>
                </li>
              ) : (
                <>
                  <li>
                    <Link className="footer__link" to="/login">
                      {props.rtlSwitch ? pageData.login_ar : pageData.login_en}
                    </Link>
                  </li>
                  <li>
                    <Link className="footer__link" to="/register">
                      {props.rtlSwitch
                        ? pageData.register_for_free_content_ar
                        : pageData.register_for_free_content_en}
                    </Link>
                  </li>
                </>
              )}
            </ul>
            <ul className="footer__list">
              <li className="footer-links__title">
                {props.rtlSwitch ? pageData.about_us_ar : pageData.about_us_en}
              </li>
              <li>
                <Link className="footer__link" to="/about">
                  {props.rtlSwitch ? pageData.about_ar : pageData.about_en}
                </Link>
              </li>
              <li>
                <Link className="footer__link" to="/faq">
                  {props.rtlSwitch ? pageData.faq_ar : pageData.faq_en}
                </Link>
              </li>
              <li>
                <Link className="footer__link" to="/legal-faq">
                  {props.rtlSwitch ? pageData.legal_ar : pageData.legal_en}
                </Link>
              </li>
            </ul>
            <ul className="footer__list">
              <li className="footer-links__title">
                {props.rtlSwitch ? pageData.contact_ar : pageData.contact_en}
              </li>
              <li>
                <a
                  className="footer__link"
                  href={"mailto:" + data?.footer_contact_email}
                >
                  {data?.footer_contact_email}
                </a>
              </li>
              <li>
                <a
                  className="footer__link"
                  href={"tel:" + data?.footer_contact_phone_no}
                >
                  {data?.footer_contact_phone_no}
                </a>
              </li>
            </ul>
          </div>
          <div className="footer__footer text-center ">
            <Stack
              direction="horizontal"
              gap={3}
              className={`flex-wrap justify-content-center mb-3 ${props.rtlSwitch ? "flex-row-reverse" : ""
                }`}
            >
              <a href="https://www.tiktok.com/@rehamalrashidi">
                <img
                  className="footer__icon"
                  src={tiktok}
                  alt="tiktok icon"
                />
              </a>
              <a href="https://www.youtube.com/user/rehamalrashidi">
                <img
                  className="footer__icon"
                  src={youtube}
                  alt="youtube icon"
                />
              </a>
              <a href="https://x.com/rehamalrashidi">
                <img className="footer__icon" src={twitter} alt="twitter icon" />
              </a>
              <a href="https://t.me/rehamalrashidi">
                <img className="footer__icon" src={telegram} alt="telegram icon" />
              </a>
              {/*<a href={data?.footer_pinterest_url}>
                <img
                  className="footer__icon"
                  src={pinterest}
                  alt="pinterest icon"
                />
              </a>*/}
              <a href="https://www.instagram.com/rehamalrashidi/">
                <img
                  className="footer__icon"
                  src={instagram}
                  alt="instagram icon"
                />
              </a>
              {/*<a href="https://www.instagram.com/rehamalrashidi/">
                <img className="footer__icon" src={google} alt="google icon" />
              </a>*/}
              {/*<a href={data?.footer_linkedin_url}>
                <img
                  className="footer__icon"
                  src={linkedin}
                  alt="linkedin icon"
                />
              </a>*/}
              <p className="mb-0 fw-bold">
                {props.rtlSwitch ? pageData.language_ar : pageData.language_en}
              </p>
            </Stack>

            <p className="fw-bold ">
              &copy; All copy right reserved to Reham house 2015-{new Date().getFullYear()}
            </p>
          </div>
        </Container>
      }

    </footer>
  );
}

// Footer.propTypes = {};

// Footer.defaultProps = {};

// export default Footer;
