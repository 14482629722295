import "./side-nav.scss";
import React, { useState, useEffect } from "react";

import { Link, useLocation } from "react-router-dom";
import { ChevronRight } from "react-bootstrap-icons";
// Components
import ProgramProgressBar from "components/ProgressBar/ProgramsProgressBar";
// data
import localData from "data/data.json";
import BeatLoader from "react-spinners/BeatLoader";
let pageData = localData.side_nav;

export default function SideNav(props) {
  const [isActive, setActive] = useState(false);
  const [isProgressBarActive, setProgressBarActive] = useState(false);
  const [insidePrograms, setInsidePrograms] = useState(false);
  const [onChapterPage, setOnChapterPage] = useState(false);
  const location = useLocation(); //

  const toggleSideNav = () => {
    setActive(!isActive);
  };
  // toggle link list
  let toggleLinks = (e) => {
    if ("programs" === e) {
      document.querySelector(`[data-list=${e}] `).classList.toggle("d-none");
      document
        .querySelector(`[data-list-link-category=${e}] `)
        .classList.toggle("ts-programs-active");
    } else {
      // =============
      let listOfCourses = document.querySelectorAll(
        `[data-list-category="course"]`
      );
      for (let course of listOfCourses) {
        course.classList.remove("ts-nav-active");
      }
      document
        .querySelector(`[data-list-course="${e}"]`)
        .classList.toggle("ts-nav-active");
      // =============
      let listOfCoursesLinks = document.querySelectorAll(
        `[data-list-link-category="course"]`
      );
      for (let link of listOfCoursesLinks) {
        link.classList.remove("ts-nav-link-active");
      }
      document
        .querySelector(`[data-list-course-link="${e}"]`)
        .classList.toggle("ts-nav-link-active");
    }
  };

  useEffect(() => {
    // Active progress bar only when is inside courses
    const pathWhenProgressBarActive = [
      "course",
      "lesson-general , suggested-courses",
    ];
    const isInsideProgramsLinkList = ["my-programs", "course", "chapter"];
    // Check if we're on specific page or not
    let areWeOnThosePages = (list) => {
      let isLinkAvailable = 0;
      list.forEach(function (pathLink) {
        isLinkAvailable += location.pathname.includes(pathLink);
      });
      if (isLinkAvailable > 0) {
        return true;
      } else {
        return false;
      }
    };
    setProgressBarActive(areWeOnThosePages(pathWhenProgressBarActive));
    setInsidePrograms(areWeOnThosePages(isInsideProgramsLinkList));
    // toggle progressbar when we've on chapter or course
    // Check if you're on chapter page or not
    if (location.pathname.includes("chapter")) {
      setOnChapterPage(true);
    } else {
      setOnChapterPage(false);
    }
  }, [location.pathname]);

  console.log('props.myCourses', props.myCourses)
  return (
    <aside className={`ts-side-nav ${isActive ? "show-sidenav" : ""}`}>
      <button className="ts-side-nav__toggle" onClick={toggleSideNav}>
        {props.rtlSwitch ? "القائمة" : "menu"}
        <ChevronRight />
      </button>
      <div className="ts-side-nav__content-outer-wrapper">
        {isProgressBarActive && (
          <div className="d-none d-xxl-block px-4 my-4 ">
            <ProgramProgressBar Progress={onChapterPage ? props.chapterProgress : props.courseProgress} />
          </div>
        )}
        <div className="ts-side-nav__content-outer">
          <div className="ts-side-nav__content">
            {isProgressBarActive && (
              <div className=" d-xxl-none px-3 mb-4">
                <ProgramProgressBar Progress={onChapterPage ? props.chapterProgress : props.courseProgress} />
              </div>
            )}
            <ul className="ts-side-nav__list">
              {insidePrograms ? (
                <li className="ts-side-nav__item">
                  <Link to="/home">
                    {props.rtlSwitch
                      ? pageData.backToHome_ar
                      : pageData.backToHome_en}
                  </Link>
                </li>
              ) : (
                <li className="ts-side-nav__item">
                  <Link to="/home">
                    {props.rtlSwitch
                      ? pageData.homePage_ar
                      : pageData.homePage_en}
                  </Link>
                </li>
              )}

              <li className="ts-side-nav__item ts-side-nav__item--list ts-side-nav__dropdown-container">
                <div>
                  <Link
                    onClick={() => {
                      toggleLinks("programs");
                    }}
                    to="my-programs"
                    className={`ts-side-nav__link-list-header ts-nav-links mb-0 ${location.pathname === "/my-programs" ? "active" : ""
                      }`}
                    data-list-link-category="programs"
                  >
                    {props.rtlSwitch
                      ? pageData.MyPrograms_ar
                      : pageData.MyPrograms_en}
                  </Link>

                  <ul
                    data-list="programs"
                    className={`ts-side-nav__link-list  ${insidePrograms ? "d-block" : "d-none"
                      }`}
                  >
                    {!props.isMyCoursesLoaded ? (<li className="ts-side-nav__link-list--course">
                          <div className="d-flex align-items-center justify-content-center">
                            <BeatLoader color="#eb8eba"/>
                          </div>
                        </li>
                    ) : (
                        props.myCourses?.map((course, courseIndex) => {
                          return (
                              <li
                                  key={courseIndex}
                                  data-list={`Course${courseIndex}`}
                                  className="ts-side-nav__link-list--course"
                              >
                                <div>
                                  <Link
                                      onClick={() => {
                                        toggleLinks(`Course${courseIndex}`);
                                      }}
                                      className="ts-side-nav__link-list-header ts-nav-links ts-nav-links mb-0"
                                      data-list-link-category="course"
                                      data-list-course-link={`Course${courseIndex}`}
                                      to={`/course/${course.id}`}
                                  >
                                    {/* <Link to={`/course/${course.id}`}> */}
                                    {props.rtlSwitch
                                        ? course.name_ar
                                        : course.name_en}
                                    {/* </Link> */}
                                  </Link>
                                  <ul
                                      className="ts-side-nav__link-list"
                                      data-list-course={`Course${courseIndex}`}
                                      data-list-category="course"
                                  >
                                    {/* Show get started only when there is data on server*/}
                                    {course.get_started_files.length === 0 ? "" :
                                        <li className="ts-nav-links">
                                          <Link to={`course/${course.id}/get-started`}>
                                            {props.rtlSwitch
                                                ? pageData.get_started_ar
                                                : pageData.get_started_en}
                                          </Link>
                                        </li>
                                    }

                                    {course.chapters.map((chapter, index2) => {
                                      return (
                                          <li className="ts-nav-links" key={index2}>
                                            <Link
                                                to={`course/${course.id}/chapter/${chapter.id}`}
                                            >
                                              {props.rtlSwitch
                                                  ? chapter.name_ar
                                                  : chapter.name_en}
                                            </Link>
                                          </li>
                                      );
                                    })}
                                    <li className="ts-nav-links">
                                      <Link
                                          to={`/course/${course.id}/bonus-materials`}
                                      >
                                        {props.rtlSwitch
                                            ? pageData.bonus_material_ar
                                            : pageData.bonus_material_en}
                                      </Link>
                                    </li>
                                    {/* {course.bonus_materials.map(
                                (bonusMaterial, index) => {
                                  return (
                                    <li className="ts-nav-links" key={index}>
                                      <Link
                                        to={`/course/${course.id}/bonus-materials`}
                                      >
                                        {props.rtlSwitch
                                          ? bonusMaterial.name_ar
                                          : bonusMaterial.name_en}
                                      </Link>
                                    </li>
                                  );
                                }
                              )} */}
                                  </ul>
                                </div>
                              </li>
                          );
                        })
                    )}
                  </ul>
                </div>
              </li>

              <li className="ts-side-nav__item">
                <Link to="/wishlist">
                  {props.rtlSwitch
                    ? pageData.Wishlist_ar
                    : pageData.Wishlist_en}
                </Link>
              </li>
              <li className="ts-side-nav__item">
                <Link to="/suggested-courses">
                  {props.rtlSwitch
                    ? pageData.SuggestedCourses_ar
                    : pageData.SuggestedCourses_en}
                </Link>
              </li>
              <li className="ts-side-nav__item">
                <Link to="/faq">
                  {props.rtlSwitch ? pageData.Faqs_ar : pageData.Faqs_en}
                </Link>
              </li>
              <li className="ts-side-nav__item">
                <Link to="/calendar">
                  {props.rtlSwitch
                    ? pageData.Calendar_ar
                    : pageData.Calendar_en}
                </Link>
              </li>
              <li className="ts-side-nav__item active-bg ">
                <Link to="/inspiring-stories">
                  {props.rtlSwitch
                    ? pageData.InspiringStories_ar
                    : pageData.InspiringStories_en}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </aside>
  );
}
