import axios from "axios";
// let token = JSON.parse(localStorage.getItem("user-token"));
export default axios.create({
 // baseURL: "https://reham.mukkancom.dev/api/",
 //    baseURL: "http://reham_laravel.local/api/",
    // headers: { Authorization: `Bearer ${token}` },
    baseURL: "https://admin.reham.com/api/",
headers: {
        'Access-Control-Allow-Origin': '*'
    },
});