import React from "react";
import MyProgramCard from "./MyProgramCard";

export default function MyPrograms(props) {
    /*
    {loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <BeatLoader color="#eb8eba" />
        </div>
      ) :
      * */
  return (
    <div
      className={
        "ts-courses-group " +
        (props?.groupCol === "2" ? "ts-courses-group--2-cards" : "")
      }
    >
      {props?.myCourses?.map((course, index) => {
        return (
          <MyProgramCard
            course={course}
            key={index}
            rtlSwitch={props.rtlSwitch}
          />
        );
      })}
    </div>
  );
}
