import "./chapter.scss";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import ChapterFromDetails from "./ChapterForDetails";
//
import DownloadImg from "../../assets/icons/download-tag.svg";
import NoteImg from "../../assets/icons/noteTag.svg";
import PlayBtnImg from "../../assets/icons/playBtnTag.svg";
export default function ChaptersFromDetails(props) {
  return (
    <div className="ts-chapters-for-details">
      <Container>
        <Row className="text-center text-gray-02 ts-chapters-for-details-cards ">
          <Col className="d-flex justify-content-center">
            <a className="ts-chapters-for-details-card" href="#">
              <img className="w-100 mb-07" src={NoteImg} alt="..." />
              <h5>Lorem Ipsum Dolor Sit Amet Consetetur</h5>
            </a>
          </Col>
          <Col className="d-flex justify-content-center">
            <a className="ts-chapters-for-details-card" href="#">
              <img className="w-100 mb-07" src={PlayBtnImg} alt="..." />
              <h5>Lorem Ipsum Dolor Sit Amet Consetetur</h5>
            </a>
          </Col>
          <Col className="d-flex justify-content-center">
            <a className="ts-chapters-for-details-card" href="#">
              <img className="w-100 mb-07" src={DownloadImg} alt="..." />
              <h5>Lorem Ipsum Dolor Sit Amet Consetetur</h5>
            </a>
          </Col>
        </Row>
        <div className="ts-chapters-for-details__main">
          {props.chaptersList.map((chapter, index) => {
            return (
              <ChapterFromDetails
                key={index}
                chapter={chapter}
                rtlSwitch={props.rtlSwitch}
              />
            );
          })}
        </div>
      </Container>
    </div>
  );
}
