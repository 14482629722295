import React from "react";

// import ChapterImg from "../../assets/images/place-holder-image-dark.png";
export default function ChapterForDetails(props) {
  return (
    <div className="ts-chapter-details">
      <img
        className="ts-chapter-details__img"
        src={props.chapter.thumb_image_url}
        alt="..."
      />

      <div>
        <h3 className="text-primary-07">
          {props.rtlSwitch ? props.chapter.name_ar : props.chapter.name_en}
        </h3>
        <p
          className="ts-desc-lg"
          dangerouslySetInnerHTML={{
            __html: props.rtlSwitch
              ? props.chapter.description_ar
              : props.chapter.description_en,
          }}
        ></p>
      </div>
    </div>
  );
}
